import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '../../Tooltip';
import {inputStyles} from './styles';
import {type InputWrapperProps} from './types';

function InputWrapper({children, label, tooltip, error, hideError, endSlot}: InputWrapperProps) {
  return (
    <Box sx={inputStyles.root}>
      <Box sx={inputStyles.label}>
        <Typography color="text.primary" fontWeight="500" mr={1}>
          {label}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoOutlinedIcon fontSize="inherit" sx={{mb: -0.25}} />
          </Tooltip>
        )}
      </Box>
      <Box sx={inputStyles.inputRow}>
        {children}
        {endSlot}
      </Box>
      {!hideError && (
        <Typography variant="body2" color="error" sx={inputStyles.error}>
          {error}
        </Typography>
      )}
    </Box>
  );
}

export default InputWrapper;
